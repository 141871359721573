<template>
  <a-modal
    width="75%"
    title="设置轮播图区域名称"
    okText="确定"
    cancelText="取消"
    @cancel="cancel"
    :maskClosable="false"
    :keyboard="false"
    :visible="showModal"
    @ok="submitData"
  >
    <div class="modal_content">
      <a-form-model
        layout="horizontal"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 6 }"
        :model="bannerAreaDetail"
        :rules="rules"
        ref="contentForm"
      >
        <a-form-model-item label="是否显示轮播图区域名称" prop="status">
          <a-select v-model="bannerAreaDetail.status" placeholder="请选择类型">
            <a-select-option :value="1"> 显示 </a-select-option>
            <a-select-option :value="0"> 不显示 </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          class="wrapperDiv"
          v-show="bannerAreaDetail.status === 1"
          label="请设置轮播图区域名称"
          prop="name"
        >
          <a-input
            v-model="bannerAreaDetail.areaName"
            placeholder="请输入"
            :maxLength="20"
            @change="change"
          />
          <div class="countDiv">
            <span>{{ countNumber }}</span
            >/20
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
    },
    bannerAreaDetail: {
      type: Object,
    },
  },
  data() {
    return {
      countNumber:0,
      rules: {
        // 表单验证规则
        status: [
          {
            required: true,
            message: "请选择跳转类型",
            trigger: "change",
          },
        ],
        areaName: [
          {
            required: true,
            message: "请输入轮播图区域名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    bannerAreaDetail: {
      handler(newVal, oldVal) {
        if(newVal.areaName){
          this.countNumber = newVal.areaName.length
        }
      },
      immediate: true,
    },
  },
  methods: {
    submitData() {
      this.$refs.contentForm.validate((valid) => {
        if (valid || (!valid && this.bannerAreaDetail.status === 0)) {
          // if (this.bannerAreaDetail.status === 0) this.bannerAreaDetail.areaName = "";
          this.$emit("submitDataArea", this.bannerAreaDetail);
        }
      });
    },
    cancel() {
      this.$emit("cancelBanner");
    },
    change() {
      this.bannerAreaDetail.areaName = this.bannerAreaDetail.areaName.replace(/\s/g, "");
      this.countNumber = this.bannerAreaDetail.areaName.length;
    },
  },
};
</script>

<style lang='less' scoped>
.modal_content {
  max-height: 600px;
  overflow: auto;
}
/deep/.wrapperDiv .ant-form-item-control-wrapper {
  width: 38%;
}
.wrapperDiv {
  position: relative;
}
.countDiv {
  color: #bfbfbf;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
</style>