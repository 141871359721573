import API from '@/request'
export const mixin = {
    data(){
        return{

        }
    },
    methods:{
        async moveFun(record,sortType){
            try {
                await API.moveBanner({
                    location:record.location,
                    id:record.bannerId,
                    sortType:sortType
                })
                this.getList()
            }catch (e) {
                console.log(e)
            }
        },
        topFun(record){
            this.moveFun(record,3)
        },
        downFun(record){
            this.moveFun(record,2)
        },
        upFun(record){
            this.moveFun(record,1)
        },
    },

    beforeRouteEnter(to, from, next) {
        // 在当前路由改变，但是该组件被复用时调用
        // 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
        // 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
        // 可以访问组件实例 `this`
        next(vm=>{
            vm.resetData();
            vm.typeValue = vm.$route.meta.type;
            vm.getList();
        });


    },
}
